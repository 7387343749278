import { Stack, styled, Switch } from "@mui/material";

export const OmakaseFiveSwitch = ({ setIsOmakaseFive, setSliderValue, disabled }) => {
  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setIsOmakaseFive(isChecked);
    setSliderValue((value) => {
      return (isChecked && value < 5) ? 5 : value
    })
  };

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <p style={{ fontSize: "10px", fontWeight: "bold" }}>通常ver.</p>
      <CustomSwitch defaultChecked={false} onChange={handleChange} disabled={disabled} />
      <p style={{ fontSize: "10px", fontWeight: "bold" }}>おま５ver.</p>
    </Stack>
  )
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'red',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));