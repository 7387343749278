import { DndContext, DragOverlay, PointerSensor, pointerWithin, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { useState } from "react"
import { charactersData } from "../data/CharactersData"
import { arrayMove } from "@dnd-kit/sortable"
import { CharacterPool } from "./CharacterPool"
import { Character } from "./Character"
import { OmakaseTierList } from "./OmakaseTierList"

export const MyOmakaseTierTable = ({ tierLists, setTierLists }) => {

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor)
  )

  const [activeCharacter, setActiveCharacter] = useState()

  const findTierList = (unique) => {
    if (unique === null) {
      return null
    }

    if (tierLists.some((tierList) => tierList.id === unique)) {
      return tierLists.find((tierLists) => tierLists.id === unique) ?? null
    }

    const id = unique
    const itemWithTierListId = tierLists.flatMap((tierLists => {
      const tierListId = tierLists.id
      return tierLists.characters.map((character) => ({ characterId: character.id, tierListId: tierListId }))
    }))

    const tierListId = itemWithTierListId.find((character) => character.characterId === id)?.tierListId
    return tierLists.find((tierList) => tierList.id === tierListId) ?? null
  }

  const handleDragStart = (event) => {
    const { active } = event
    setActiveCharacter(charactersData.find((character) => character.id === active.id))
  }

  const handleDragCancel = () => {
    setActiveCharacter(null)
  }

  const handleDragOver = (event) => {
    // activeは今触っているオブジェクト、overは重なっているオブジェクト、deltaは移動情報？
    const { active, over, delta } = event
    // 動かしているファイターのid
    const activeId = active.id
    // 重なっているファイターのid
    const overId = over ? over.id : null
    // 動かしたファイターの元々のtier
    const activeTierList = findTierList(activeId)
    // 動かしたファイターが今重なっているtier
    const overTierList = findTierList(overId)

    if (!activeTierList || !overTierList || activeTierList === overTierList) {
      return null
    }
    setTierLists((prevState) => {
      const activeCharacters = activeTierList.characters
      const overCharacters = overTierList.characters
      // handleDragEndと違う場所に書かれているのが気になる
      const activeIndex = activeCharacters.findIndex((character) => character.id === activeId)
      const overIndex = overCharacters.findIndex((character) => character.id === overId)
      // 今の場所で挿入するとした場合のoverTierListでのindexを求める（intを返す）
      const newIndex = () => {
        // && delta.y > 0　いる？
        const putOnRightCharacter = overIndex === overCharacters.length - 1 && delta.y > 0
        const modifier = putOnRightCharacter ? 1 : 0
        // overIndexって0以上かnull?
        return overIndex >= 0 ? overIndex + modifier : overCharacters.length + 1
      }
      return prevState.map((tierList) => {
        // 動かしたファイターが含まれるTierの更新
        if (tierList.id === activeTierList.id) {
          tierList.characters = activeCharacters.filter((character) => character.id !== activeId)
          return tierList
        } else if (tierList.id === overTierList.id) {
          tierList.characters = [
            ...overCharacters.slice(0, newIndex()),
            activeCharacters[activeIndex],
            ...overCharacters.slice(newIndex(), overCharacters.length)
          ].filter((character) => character !== undefined) //この記述を入れないとnullになる
          return tierList
        } else {
          // 関係ないTier
          return tierList
        }
      })
    })
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // 動かしているファイターのid
    const activeId = active.id
    // 重なっているファイターのid
    const overId = over ? over.id : null
    // 動かしたファイターの元々のtier
    const activeTierList = findTierList(activeId)
    // 動かしたファイターが今重なっているtier
    const overTierList = findTierList(overId)
    if (!activeTierList || !overTierList || activeTierList !== overTierList) {
      return null
    }
    const activeIndex = activeTierList.characters.findIndex((character) => character.id === activeId)
    const overIndex = overTierList.characters.findIndex((character) => character.id === overId)
    // よくわからん
    if (activeIndex !== overIndex) {
      setTierLists((prevState) => {
        return prevState.map((tierList) => {
          if (tierList.id === activeTierList.id) {
            tierList.characters = arrayMove(overTierList.characters, activeIndex, overIndex)
            return tierList
          } else {
            return tierList
          }
        })
      })
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px", alignItems: "flex-end" }}>
        <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
      </div>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />

      <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragCancel={handleDragCancel}>
        <div>
          {tierLists.map((tierList) => {
            // omakaseTierはidが文字列のため
            if (tierList.id === "0") {
              return null
            }
            return <OmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList} ></OmakaseTierList>
          })}
          {tierLists.map((tierList) => {
            if (tierList.id === "0") {
              return <CharacterPool key={tierList.id} id={tierList.id} rank={tierList.rank} characters={tierList.characters} />
            }
            return null
          })}
        </div>

        <DragOverlay style={{ transformOrigin: "0 0 " }}>
          {activeCharacter ? <Character id={activeCharacter.id} characterName={activeCharacter.characterName} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  )
}