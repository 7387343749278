import { PlayerOmakaseTierList } from "./PlayerOmakaseTierList"
import { RandomFighterPicker } from "./RandomFighterPicker"


export const PlayerOmakaseTierTable = ({ tierLists, isLoggedInUser }) => {
  return (
    <>
      <p style={{ display: "block", fontSize: "18px", fontWeight: "bold" }}>おまかせランク</p>
      <hr style={{ border: "none", height: "2px", background: "black", margin: "0px" }} />
      {isLoggedInUser &&
        <RandomFighterPicker tierLists={tierLists} />
      }
      <div id='omakase-tier-table'>
        {tierLists.map((tierList) => {
          return <PlayerOmakaseTierList key={tierList.id} id={tierList.id} characters={tierList.characters} tierList={tierList}/>
        })}
      </div>
    </>
  )
}