import { Link, useLocation, useSearchParams } from "react-router-dom"
import { PlayerCalendar } from "./PlayerCalendar"
import { PlayerTierTable } from "./PlayerTierTable"
import { useEffect, useState } from "react";
import { axiosInstance } from "../../configs/axios";
import { Footer } from "../footer/Footer";
import { useModal } from "../../hooks/useModal";
import { OfferBattleModal } from "./OfferBattleModal";
import { Header } from "../header/Header";
import { LoadingScreen } from "../common/LoadingScreen";
import { playerTierListData } from "../data/playerTierListData";
import { TierListData } from "../data/TierListData";
import { charactersMap } from "../data/CharactersMap";
import { rankMap } from "../data/RankMap";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ScreenshotButton } from "./ScreenshotButton";
import { PlayerRequestTierTable } from "./PlayerRequestTierTable";
import { requestData } from "../data/requestData";
import { requestMap } from "../data/RequestMap";
import { PlayerOmakaseTierTable } from "./PlayerOmakaseTierTable";

export const PlayerDetail = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [tierLists, setTierLists] = useState(playerTierListData)

  //とりあえずの初期化
  const [requestTierLists, setRequestTierLists] = useState([])

  const [searchParams] = useSearchParams();

  const userId = searchParams.get("userid")

  const { user, beingLoggedIn, setBeingLoggedIn } = useAuthContext();

  const [player, setPlayer] = useState();

  const [like, setLike] = useState();

  const [omakaseTierLists, setOmakaseTierLists] = useState([])

  const { isOpen, onClose, onOpen } = useModal();

  const { state } = useLocation()

  useEffect(() => {
    setIsLoading(true)
    window.scrollTo({
      top: 0
    });

    axiosInstance.post("/user", userId, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const data = response.data
      if (state && data) {
        data.bitCalendar = state.bitCalendar
      }
      setPlayer(data)
    })

    if (!state) {
      Promise.all([
        axiosInstance.get(`/tier?userid=${userId}`),
        axiosInstance.get(`/requesttier?userid=${userId}`),
        axiosInstance.get(`/userinfo?userid=${userId}`)
      ]).then(([
        tierResponse,
        requestTierResponse,
        userInfoResponse
      ]) => {
        const tierListMap = new Map()
        tierResponse.data.forEach((tier) => {
          tierListMap.set(tier.id,
            {
              id: tier.id,
              rate: rankMap.get(tier.id).rate,
              rank: rankMap.get(tier.id).rank,
              characters: tier.characterIdList.map(id => charactersMap.get(id)),
            })
        });

        const requestTierListMap = new Map()
        requestTierResponse.data.forEach((request) => {
          requestTierListMap.set(request.id,
            {
              id: request.id,
              text: requestMap.get(request.id).text,
              characters: request.characterIdList.map(id => charactersMap.get(id)),
            })
        });

        setTierLists(() =>
          TierListData.map((tierList) => {
            if (tierListMap.has(tierList.id)) {
              return tierListMap.get(tierList.id)
            } else {
              tierList.characters = []
              return tierList
            }
          })
            .filter((tierList) => { return tierList.id !== 0 })
            .filter((tierList) => { return tierList.characters.length !== 0 })
        );

        setRequestTierLists(() =>
          requestData.map((request) => {
            if (requestTierListMap.has(request.id)) {
              return requestTierListMap.get(request.id)
            } else {
              request.characters = []
              return request
            }
          })
            .filter((request) => { return request.id !== 0 })
            .filter((requestTierList) => { return requestTierList.characters.length !== 0 })
        );

        const userInfo = userInfoResponse.data
        setOmakaseTierLists(
          JSON.parse(userInfo.omakaseTier)
          .filter((tierList) => { return tierList.id !== "0" })
          .filter((tierList) => { return tierList.characters.length !== 0 })
        )

        setIsLoading(false)
      })
    }

    if (state) {
      setPlayer(() => {
        const newPlayer = state.user
        newPlayer.bitCalendar = state.bitCalendar
        return newPlayer
      })
      setTierLists(
        state.tierLists
          .filter((tierList) => { return tierList.id !== 0 })
          .filter((tierList) => { return tierList.characters.length !== 0 })
      )
      setRequestTierLists(
        state.requestTierLists
          .filter((requestTierList) => { return requestTierList.id !== 0 })
          .filter((requestTierList) => { return requestTierList.characters.length !== 0 })
      )
      setOmakaseTierLists(
        state.omakaseTierLists
          .filter((tierList) => { return tierList.id !== "0" })
          .filter((tierList) => { return tierList.characters.length !== 0 })
      )
      setIsLoading(false)
    }
  }, []);

  useEffect(() => {
    if (user && user.id !== userId) {
      axiosInstance.get(`/like?userid=${user.id}&likeduserid=${userId}`)
        .then((response) => {
          setLike(response.data)
          setBeingLoggedIn(false)
        })
    }


  }, [user])

  const postLike = () => {
    setLike(like => {
      axiosInstance.post(`/like/register?userid=${user?.id}&likeduserid=${userId}&islike=${!like}`)
      return !like
    })
  }

  const convertPlayerToProfile = (p) => {
    const screenName = p?.screenName
    const postLink = p?.postLink

    if (screenName) {
      return "https://x.com/" + screenName;
    }

    if (postLink) {
      const index = postLink.indexOf('/status');
      return (index !== -1) ? postLink.substring(0, index) : null;
    }

    return null
  }

  return (
    <>
      <div style={{
        width: "100%",
        maxWidth: "600px",
        margin: "0px auto",
        padding: "20px"
      }}>
        {(isLoading || beingLoggedIn) && <LoadingScreen />}
        <Header />
        <Link className="link" to={"/"} style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>トップページ</Link>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {user ?
            <a className="link" href={convertPlayerToProfile(player)} target="_blank"
              rel="noopener noreferrer" style={{ display: "block", fontSize: "24px", fontWeight: "bold", textDecoration: "none" }}>{player?.name}
            </a> :
            <p style={{ display: "block", fontSize: "24px", fontWeight: "bold", textDecoration: "none" }}>{player?.name}
            </p>
          }
          {userId === user?.id ?
            <Link className="blackButton" to="/mypage" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページを編集</Link> :
            user &&
            <button onClick={postLike} style={{ background: "white", border: "none", margin: "5px", cursor: "pointer" }}>
              <img src={like ? "images/ハート.png" : "images/空ハート.png"} alt="ハート" style={{ width: "25px", objectFit: "cover" }} />
            </button>
          }
        </div>

        <PlayerTierTable userId={userId} tierLists={tierLists} setTierLists={setTierLists} />
        {(tierLists.length !== 0 && userId === user?.id) &&
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"tier-table"} />
          </div>
        }
        {tierLists.length === 0 ?
          userId === user?.id ?
            <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", margin: "12px" }}>
              まだランクが登録されていません。<br />
              マイページを編集して、ランクを登録しましょう。
            </p>
            :
            <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", margin: "12px" }}>
              まだランクが登録されていません。
            </p>
          :
          <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
            ランクの基準には、以下2つのサイトの指標を用いさせて頂いています。<br />
            ・<a className="externalLink" href="https://smashmate.net/ranking/" target="_blank" rel="noopener noreferrer">スマメイト様のレート</a><br />
            ・<a className="externalLink" href="https://kumamate.net/vip/" target="_blank" rel="noopener noreferrer">クマメイト様の世界戦闘力の段位</a><br />
            製作者の完全な主観によって、基準をマッピングさせて頂いています。ご了承ください。本サイトのランクは、いずれか一方の基準を満たしていれば十分とします。
          </p>
        }
        <PlayerRequestTierTable requestTierLists={requestTierLists}></PlayerRequestTierTable>
        {(requestTierLists.length !== 0 && userId === user?.id) &&
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"request-tier-table"} />
          </div>
        }

        {requestTierLists.length === 0 ?
          userId === user?.id ?
            <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", margin: "12px" }}>
              まだ対戦したいファイターが登録されていません。<br />
              マイページを編集して、登録しましょう。
            </p>
            :
            <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", margin: "12px" }}>
              まだ対戦したいファイターが登録されていません。
            </p>
          :
          <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
            本プレイヤーが対戦を希望しているファイターです。対戦申し込み時に、目安としてご活用下さい。
          </p>
        }

        { omakaseTierLists.length !== 0 &&
        <div>
          <PlayerOmakaseTierTable tierLists={omakaseTierLists} isLoggedInUser={userId === user?.id}/>
        {(userId === user?.id) &&
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ScreenshotButton type={"omakase-tier-table"} />
          </div>
        }
        </div>
        }

        <PlayerCalendar player={player} />
        <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
          対戦予定を円滑に決定するために目安としてご活用ください。
        </p>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
          {player?.postLink ?
            user ?
              userId !== user?.id &&
              <button className="blackButton" onClick={() => onOpen('offerBattle')}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "35px",
                  width: "150px",
                  color: "white",
                  background: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: "10px",
                  textDecoration: "none",
                  padding: "10px",
                  cursor: "pointer"
                }}>対戦を申し込む
              </button>
              : <div style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center"
              }}>
                <p style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}>
                  Xでログインが必要です。
                </p>
                <button
                  className="blackButton"
                  onClick={() => onOpen('offerBattle')}
                  disabled={true}  // ボタンを無効化
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                    width: "150px",
                    color: "#ccc",
                    background: "#888",
                    fontSize: "12px",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "10px",
                    textDecoration: "none",
                    padding: "10px",
                    cursor: "not-allowed",
                    marginTop: "5px"
                  }}
                >
                  対戦を申し込む
                </button>
              </div>
            :
            userId !== user?.id ?
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center"
              }}>
                <p style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}>
                  ポストが登録されていません
                </p>
                <button
                  className="blackButton"
                  onClick={() => onOpen('offerBattle')}
                  disabled={true}  // ボタンを無効化
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                    width: "150px",
                    color: "#ccc",
                    background: "#888",
                    fontSize: "12px",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "10px",
                    textDecoration: "none",
                    padding: "10px",
                    cursor: "not-allowed",
                    marginTop: "5px"
                  }}
                >
                  対戦を申し込む
                </button>
              </div>
              :
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center"
              }}>
                <p style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}>
                  ポストが登録されていません
                </p>
                <Link className="blackButton" to="/mypage" style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "35px",
                  width: "120px",
                  color: "white",
                  background: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: "10px",
                  textDecoration: "none",
                  margin: "5px"
                }}>マイページを編集</Link>
              </div>
          }
        </div>
        <Footer />
        {isOpen('offerBattle') && <OfferBattleModal onClose={onClose} player={player} user={user} />}
      </div>
    </>
  )
}