import { Link, useNavigate } from "react-router-dom"
import { MyTierTable } from "./MyTierTable"
import { MyCalendar } from "./MyCalendar"
import { useEffect, useState } from "react"
import { axiosInstance } from "../../configs/axios"
import { MyPageHeader } from "../header/MyPageHeader"
import { Footer } from "../footer/Footer"
import { RegisterPostModal } from "./RegisterPostModal"
import { useModal } from "../../hooks/useModal"
import { TierListData } from "../data/TierListData"

import "../../styles/link.css"
import "../../styles/blackButton.css"
import { LoadingScreen } from "../common/LoadingScreen"
import { rankMap } from "../data/RankMap"
import { charactersMap } from "../data/CharactersMap"
import { useAuthContext } from "../../hooks/useAuthContext"
import { MyRequestTierTable } from "./MyRequestTierTable"
import { requestMap } from "../data/RequestMap"
import { requestData } from "../data/requestData"
import { MyOmakaseTierTable } from "./MyOmakaseTierTable"

export const MyDetail = () => {
  const [isLoading, setIsLoading] = useState(true)

  const { user, screenName } = useAuthContext();

  const { isOpen, onClose, onOpen } = useModal();

  const [tierLists, setTierLists] = useState([])

  const [requestTierLists, setRequestTierLists] = useState([])

  const [omakaseTierLists, setOmakaseTierLists] = useState([])

  const [events, setEvents] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    window.scrollTo({
      top: 0
    });
  }, []);

  useEffect(() => {
    Promise.all([
      axiosInstance.get(`/mytier?userid=${user?.id}`),
      axiosInstance.get(`/requesttier?userid=${user?.id}`),
      axiosInstance.get(`/userinfo?userid=${user?.id}`)
    ]).then(([tierResponse, requestTierResponse, userInfoResponse]) => {
      const tierListMap = new Map()
      tierResponse.data.forEach((tier) => {
        tierListMap.set(tier.id,
          {
            id: tier.id,
            rate: rankMap.get(tier.id).rate,
            rank: rankMap.get(tier.id).rank,
            characters: tier.characterIdList.map(id => charactersMap.get(id)),
          })
      });

      const requestTierListMap = new Map()
      requestTierResponse.data.forEach((request) => {
        requestTierListMap.set(request.id,
          {
            id: request.id,
            text: requestMap.get(request.id).text,
            characters: request.characterIdList.map(id => charactersMap.get(id)),
          })
      });

      setTierLists(() => {
        const newTierLists = TierListData.map((tierList) => {
          if (tierListMap.has(tierList.id)) {
            return tierListMap.get(tierList.id)
          } else {
            //TODO: TierListDataが参照型のためずれるが応急処置
            tierList.characters = []
            return tierList;
          }
        })
          // ファイターがいないティアは削除
          .filter((tierList) => { return tierList.characters.length !== 0 || tierList.id === 0 })

        if (checkEmptyTiers(newTierLists)) {
          const tierList_1500 = {
            id: 1500,
            rate: '1500',
            rank: '20',
            characters: []
          }

          return [newTierLists.find((tl) => tl.id === 0), tierList_1500]
        }
        return newTierLists
      });

      setRequestTierLists(() =>
        requestData.map((request) => {
          if (requestTierListMap.has(request.id)) {
            return requestTierListMap.get(request.id)
          } else {
            request.characters = []
            return request
          }
        }));
      
      const userInfo = userInfoResponse.data

      setOmakaseTierLists(JSON.parse(userInfo.omakaseTier))
      // TODO: データ破損時初期化処理
      setIsLoading(false)
    })

  }, [user])

  const checkEmptyTiers = (tiers) => {
    return tiers.every(tier => {
      if (tier.id !== 0) {
        return tier.characters.length === 0;
      }
      return true;
    });
  }

  const eventsToBitConverter = (events) => {
    const HOURS = 24;
    const DAYS = 7;

    const bitEvents = new Array(HOURS * DAYS).fill(0)
    events.forEach(event => {
      const hour = parseInt(event.startTime.split(':')[0])
      const day = event.daysOfWeek[0];

      bitEvents[day * HOURS + hour] = 1

    });

    return bitEvents.join('');
  }

  const handleClieckSaveButton = () => {
    axiosInstance.post(`/mytier/register?userid=${user?.id}`, tierLists);
    axiosInstance.post(`/myrequesttier/register?userid=${user?.id}`, requestTierLists);
    axiosInstance.post(
      `/userinfo/register?userid=${user?.id}`,
      {
        userId: user?.id,
        omakaseTier: JSON.stringify(omakaseTierLists),
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      );

    // userがログイン状態が切り替わらないとGetメソッドで取得しないため
    const bitCalendar = eventsToBitConverter(events)
    user.bitCalendar = bitCalendar
    axiosInstance.post(
      `/mycalendar/register?userid=${user.id}`,
      JSON.stringify(bitCalendar),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    navigate(
      `/playerpage?userid=${user?.id}`,
       { 
        state: 
        {
          tierLists: tierLists,
          bitCalendar: eventsToBitConverter(events),
          user: user,
          requestTierLists: requestTierLists,
          omakaseTierLists: omakaseTierLists
        }
      }
    )
  }

  return (
    <>
      <div style={{
        width: "100%",
        maxWidth: "600px",
        margin: "0px auto",
        padding: "20px"
      }}>
        {isLoading && <LoadingScreen />}
        <MyPageHeader handleClieckSaveButton={handleClieckSaveButton} onOpen={onOpen}/>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <Link className="link" to="/" style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }} state={{ test: "aa" }}>
              トップページ
            </Link>
            <p style={{ display: "block", fontSize: "14px", fontWeight: "bold", margin: "auto 5px" }}>
              ＞
            </p>
            <Link className="link" to={`/playerpage?userid=${user?.id}`} style={{ fontSize: "16px", fontWeight: "bold", textDecoration: "none" }}>
              マイページ
            </Link>
          </div>
          {!user?.postLink &&
            <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", width: "120px", textAlign: "center" }}>
              ポストが未登録
            </p>}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          <p style={{ display: "block", fontSize: "24px", fontWeight: "bold" }}>{user?.name}</p>
        </div>

        <MyTierTable user={user} tierLists={tierLists} setTierLists={setTierLists}></MyTierTable>
        <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
          あなたの各ファイターの強さ
          ランクの基準には、以下2つのサイトの指標を用いさせて頂いています。<br />
          ・<a className="externalLink" href="https://smashmate.net/ranking/" target="_blank" rel="noopener noreferrer">スマメイト様のレート</a><br />
          ・<a className="externalLink" href="https://kumamate.net/vip/" target="_blank" rel="noopener noreferrer">クマメイト様の世界戦闘力の段位</a><br />
          製作者の完全な主観によって、基準をマッピングさせて頂いています。ご了承ください。本サイトのランクは、いずれか一方の基準を満たしていれば十分とします。
        </p>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
          <button onClick={handleClieckSaveButton} 
            className="blackButton"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページの保存</button>
        </div>

        <MyRequestTierTable requestTierLists={requestTierLists} setRequestTierLists={setRequestTierLists}></MyRequestTierTable>

        <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
          あなたが優先して対戦したいファイターを管理するのにご活用下さい。「とても対戦したい」・「対戦したい」に追加していないファイターからの対戦申し込みを
        </p>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
          <button onClick={handleClieckSaveButton} 
            className="blackButton"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページの保存</button>
        </div>

        <MyOmakaseTierTable tierLists={omakaseTierLists} setTierLists={setOmakaseTierLists}></MyOmakaseTierTable>

        <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
          あなたの「おまかせ」の強さランクを登録できます。また登録したランクを元にファイターをランダムピックする「<b>条件付きおまかせ</b>」の機能を使うことができます。「<b>条件付きおまかせ</b>」では、左右差を考慮してランダムピックされるため、<b>各ランク左右差を意識して配置</b>することをおすすめします。
          また、ファイタープールに配置したファイターはピックされません。
        </p>

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
          <button onClick={handleClieckSaveButton} 
            className="blackButton"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページの保存</button>
        </div>

        <MyCalendar user={user} events={events} setEvents={setEvents}></MyCalendar>
        <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
          他プレイヤーが対戦を申し込む際に参照致します。対戦予定を円滑に決定するためにご活用ください。
        </p>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
          <button onClick={handleClieckSaveButton} 
            className="blackButton"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "35px",
              width: "120px",
              color: "white",
              background: "black",
              fontSize: "12px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
              textDecoration: "none",
              margin: "5px"
            }}>マイページの保存</button>
        </div>
        <Footer></Footer>
        {isOpen('registerPost') && <RegisterPostModal onClose={onClose} user={user} screenName={screenName} />}
      </div>
    </>
  )
}