import { useCallback, useEffect, useRef, useState } from "react"
import { Slider } from "@mui/material";
import { CharacterImg } from "../home/CharacterImg";
import { OmakaseFiveSwitch } from "./OmakaseFiveSwitch";
import { OmakaseFiveResult } from "./OmakaseFiveResult";
import { ScreenshotButton } from "./ScreenshotButton";
import ReactGA from "react-ga4";

export const RandomFighterPicker = ({ tierLists }) => {

  const [characterList, setCharacterList] = useState([])

  const [sliderValue, setSliderValue] = useState(1) //適当な数値を入れておかないとSliderコンポーネントがアンコントロールドになる

  const [selectedValue, setSelectedValue] = useState()

  const [omakaseCharacter, setOmakaseCharacter] = useState()

  const [omakaseDate, setOmakaseDate] = useState()

  const [marks, setMarks] = useState([])

  const [isDisabled, setIsDisabled] = useState(false);

  const [countdown, setCountdown] = useState(0);

  const [isOmakaseFive, setIsOmakaseFive] = useState(false)

  const [omakaseFiveCharacters, setOmakaseFiveCharacters] = useState([])

  const [evalRank, setEvalRank] = useState("")

  const parentRef = useRef(null)

  const WAITING_TIME = 30

  const sortCharacterList = (tls) => {
    let sortedCharacterList = []
    tls.forEach((tl) => {
      tl.characters.forEach((character) => {
        sortedCharacterList.push({ ...character, rank: tl.id })
      })
    })
    return sortedCharacterList
  }

  const handleOnChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  const handleOnOmakase = () => {
    const randomIndex = Math.floor(Math.random() * sliderValue);
    setOmakaseCharacter(characterList[randomIndex]);
    setSelectedValue(sliderValue)
    setOmakaseDate(getFormattedDate)
    setIsDisabled(true);
    setCountdown(WAITING_TIME);
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "おまかせ",
    });
  }

  const handleOnOmakaseFive = () => {
    const randomIndexes = generateRandomNumArray(sliderValue, 5)
    const newOmakaseFiveCharacters = randomIndexes.map((index) => characterList[index])
    setOmakaseFiveCharacters(newOmakaseFiveCharacters)
    setSelectedValue(sliderValue)
    setOmakaseDate(getFormattedDate)
    setIsDisabled(true);
    setCountdown(WAITING_TIME);
    setEvalRank(calcEvalRank(newOmakaseFiveCharacters))
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "おま５",
    });
  }

  const calcEvalRank = (characters) => {
    let sumNum = 0
    characters.forEach((character) => {
      sumNum += rankNumMap[character.rank]
    })
    if (sumNum <= 9) {
      return "D"
    } else if (sumNum <= 13) {
      return "C"
    } else if (sumNum <= 17) {
      return "B"
    } else if (sumNum <= 21) {
      return "A"
    } else {
      return "S"
    }
  }

  const rankNumMap = {
    "S": 5,
    "A": 4,
    "B": 3,
    "C": 2,
    "D": 1
  }

  const generateRandomNumArray = (maxNum, generateArrayLength) => {
    let generateArray = []; //ランダム格納用配列
    let numberArray = []; //ランダム生成用配列

    //ランダム生成用配列を作成
    for (let i = 0; i < maxNum; i++) {
      numberArray[i] = i + 1;
    }

    //ランダム格納用配列にランダム整数を格納
    for (let j = 0, len = numberArray.length; j < generateArrayLength; j++, len--) {
      let rndNum = Math.floor(Math.random() * len);
      generateArray.push(numberArray[rndNum] - 1);
      numberArray[rndNum] = numberArray[len - 1];
    }

    return generateArray;
  }

  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  const defaultMarks = [
    { value: 1 },
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 70 },
    { value: 80 },
    { value: 86 },
  ]

  const createMarks = useCallback((characterLength) => {
    return [...defaultMarks, { value: characterLength }].filter((mark) => {
      return mark.value <= characterLength
    })
  }, []);

  useEffect(() => {
    const sortedCharacterList = sortCharacterList(tierLists)
    setCharacterList(sortedCharacterList)
    setSliderValue(sortedCharacterList.length)
    setMarks(createMarks(sortedCharacterList.length))
    setIsDisabled(true);
    setCountdown(WAITING_TIME);
  }, [tierLists])

  useEffect(() => {
    let timer;
    if (isDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000); // 1秒ごとにカウントダウン
    } else if (countdown === 0 && isDisabled) {
      setIsDisabled(false); // カウントが0になったらボタンを有効化
    }

    return () => clearInterval(timer); // クリーンアップ
  }, [isDisabled, countdown]);

  const moveNextMark = () => {
    const nextMark = marks.find(mark => mark.value > sliderValue);
    setSliderValue(nextMark ? nextMark.value : sliderValue)
  }

  const movePriviousMark = () => {
    // おま５モードならmarks1を含まないように
    const priviousMark = marks.findLast(mark => (mark.value < sliderValue && (isOmakaseFive ? 5 : 1) < sliderValue));
    setSliderValue(priviousMark ? priviousMark.value : sliderValue)
  }

  return (
    <div style={{ margin: "10px" }}>
      <div style={{ display: "flex", textAlign: "center", justifyContent: "space-between" }}>
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>条件付きおまかせ</p>
        <div style={{ textAlign: "end" }}>
          <OmakaseFiveSwitch setIsOmakaseFive={setIsOmakaseFive} setSliderValue={setSliderValue} disabled={characterList.length < 5} />
          {characterList.length < 5 && <p style={{ color: "red", fontWeight: "bold", fontSize: "8px" }}>おま５ver.を使うためには<br />おまかせランクを5体以上<br />登録してください</p>}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
        <div style={{ margin: "0px auto", width: "80%", height: "150px", position: "relative" }}>
          <div style={{ width: `${parentRef.current ? `${parentRef.current.getBoundingClientRect().width}px` : '0px'}`, height: "50px", position: "relative", margin: "0px auto" }}>
            <p style={{
              width: "70px",
              height: "50px",
              textAlign: "center",
              fontWeight: "bold",
              position: "absolute",
              left: `${parentRef.current && characterList.length !== 0
                ? `${parentRef.current.getBoundingClientRect().width * (sliderValue - 1) / (characterList.length - 1)}px`
                : '0px'
                }`,
              transform: "translateX(-30px)"
            }}><span style={{ fontSize: "40px" }}>{sliderValue}</span>体
            </p>
          </div>
          <div style={{ margin: "0px auto", width: "120%", height: "40px", display: "flex", alignItems: "center", justifyContent: "space-between", transform: "translateX(-9%)" }}>
            <button className="blackButton" style={{ height: "30px", width: "30px", background: "black", border: "none", borderRadius: "8px", color: "white", fontWeight: "bold" }} onClick={movePriviousMark}>ー</button>
            <Slider
              key={`slider-${tierLists.length === 0}`} //適当な数値を入れておくことで不要な設定かもしれない
              ref={parentRef}
              size="big"
              min={isOmakaseFive ? 5 : 1}
              max={characterList.length}
              defaultValue={100}
              value={sliderValue}
              aria-label="Small"
              marks={marks}
              step={null}
              onChange={handleOnChange}
              style={{ margin: "15px" }}
              sx={{
                "& .MuiSlider-track": {
                  backgroundColor: "black", // バーの色を黒に
                  color: "black",
                  opacity: 1,

                },
                "& .MuiSlider-rail": {
                  backgroundColor: "black",
                  color: "black",
                  opacity: 1,
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: "red", // サムの色を赤に
                  "&:hover": {
                    boxShadow: "0 0 0 3px rgba(255, 0, 0, 0.5)", // ホバー時の影の色を赤に
                  },
                  "&.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "0 0 0 5px rgba(255, 0, 0, 0.3)", // ホバー時の影の色を赤に
                  },
                },
                "& .MuiSlider-mark": {
                  backgroundColor: "black", // マークの色を黒に
                  height: "8px", // マークの高さを大きめに
                  width: "8px", // マークの幅を大きめに
                  borderRadius: "50%", // 丸くする
                  border: "2px solid black",
                  opacity: 1
                },
              }}
            />
            <button className="blackButton" style={{ height: "30px", width: "30px", background: "black", border: "none", borderRadius: "8px", color: "white", fontWeight: "bold" }} onClick={moveNextMark}>＋</button>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ width: "50px", textAlign: "start", fontWeight: "bold", transform: "translateX(-15px)" }}><span style={{ fontSize: "24px" }}>{isOmakaseFive ? 5 : 1}</span>体</p>
            <p style={{ width: "50px", textAlign: "right", fontWeight: "bold", transform: "translateX(20px)" }}><span style={{ fontSize: "24px" }}>{characterList.length}</span>体</p>
          </div>
        </div>
      </div>
      <button className="blackButton"
        style={{
          margin: "0px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35px",
          width: "140px",
          color: isDisabled ? "#ccc" : "white",
          background: isDisabled ? "#888" : "black",
          fontSize: "15px",
          fontWeight: "bold",
          border: "none",
          borderRadius: "10px",
          textDecoration: "none",
          padding: "10px",
          cursor: isDisabled ? "not-allowed" : "pointer"
        }}
        onClick={isOmakaseFive ? handleOnOmakaseFive : handleOnOmakase}
        disabled={isDisabled}
      >おまかせ
      </button>
      <div style={{ display: "flex", margin: "5px auto", height: "20px" }}>
        {isDisabled && <p style={{ width: "100%", fontSize: "14px", fontWeight: "bold", textAlign: "center" }}>{countdown}秒後にピック可</p>}
      </div>

      <div style={{ minHeight: "40px" }}>
        {(!isOmakaseFive && omakaseCharacter) &&
          <>
            <div id='omakase-result' style={{ display: "flex", margin: "20px auto 30px", width: "90%", maxWidth: "330px", justifyContent: "space-around", alignItems: "end" }}>
              <div style={{ fontWeight: "bold" }}>
                <p style={{ marginBottom: "10px" }}>
                  <span style={{ fontSize: "28px" }}>{selectedValue}</span>体からピック <span style={{ fontSize: "28px" }}>{omakaseCharacter.rank}</span>ランク
                </p>
                <p style={{ marginLeft: "5px", fontSize: "12px" }}>{omakaseDate}</p>
              </div>
              <CharacterImg characterName={omakaseCharacter.characterName} />

            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ScreenshotButton type={"omakase-result"} />
            </div>
          </>
        }
        {(isOmakaseFive && omakaseFiveCharacters.length === 5) &&
          <div style={{ width: "100%" }}>
            <OmakaseFiveResult omakaseFiveCharacters={omakaseFiveCharacters} selectedValue={selectedValue} omakaseDate={omakaseDate} evalRank={evalRank} />

            <div style={{ display: "flex", justifyContent: "end" }}>
              <ScreenshotButton type={"omakase-five-result"} />
            </div>

          </div>
        }
      </div>

      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>条件付きおまかせとは</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        スマブラSPの「おまかせ」では、得意なファイターに限定して無作為選択するような機能は搭載されていません。<br />
        本サイトの「条件付きおまかせ」を用いることで、登録したあなたのおまかせランクを元に、上位ファイターから無作為にファイターをピックすることができます。好きなファイターのみのおまかせで戦いたい、実力差のある人同士でおまかせを楽しみたい場面でご活用ください。
      </p>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>使い方</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        はじめにマイページのおまかせランクを編集して、自分のおまかせランクを作成しましょう。左右差を考慮して上位ランクからピックされますので、意識して配置してください。 <br />
        次に上のスライダーから何体のファイターからピックするかを設定してください。「おまかせ」ボタンを押せばファイターが表示されるので、試合を開始しましょう。<br />
        おまかせの信頼性担保のため、本ページにアクセス時や再抽選時は30秒間押せない仕様にしています。
      </p>
      <p style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>おすすめの遊び方</p>
      <p style={{ display: "block", fontSize: "12px", margin: "12px" }}>
        <strong>1.</strong>  スライダーの数値を一番左の状態、すなわちファイター固定で対戦を始めてください。<br />
        <strong>2.</strong>  勝ったら「＋」ボタンでスライダーの数値を増やし、負けたら「ー」ボタンで減らします。勝ったらおまかせの条件を厳しく、負けると緩くします。<br />
        <strong>3.</strong>  スライダーの数値が一番右の状態、すなわち全キャラのおまかせで勝つと勝利です。<br /><br />
        決着に時間がかなりかかる想定ですので、スライダーの初期位置・勝った時負けた時の「±」ボタンの押す回数は各自調整して遊んでみてください。
      </p>
    </div>
  )
}